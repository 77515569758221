.landing-page {
  /*background: url('../../../public/resources/images/AdobeStock/AdobeStock_332215667.jpeg');*/
  object-fit: contain;
}

.landing-page-error {
  @apply border-2 rounded-2xl bg-black p-1 shadow shadow-amber-950;
  width: 100%;
  height: 620px;
}

.background-video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.landing-page-container {
  position: relative;
  text-align: center;
  color: white;
}

.landing-page-text {
  font-weight: bolder;
  font-size: 3rem;
  width: 80%;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
