body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  /* background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0.9) 0%,
    rgba(218, 180, 139, 0.9) 100%
  ); */
  background-color: white !important;
  text-align: center;
}

/*@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700;800&display=swap");*/

/*html,*/
/*body {*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  overflow-x: hidden;*/
/*}*/

/** {*/
/*  box-sizing: border-box;*/
/*}*/

/*body {*/
/*  margin: 0;*/
/*  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",*/
/*  "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",*/
/*  "Helvetica Neue", sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*}*/

/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",*/
/*  monospace;*/
/*}*/

/*#root {*/
/*  width: 100%;*/
/*  height: 100%;*/
/*}*/
