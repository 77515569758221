.privacy-policy {
  max-width: 1000px;
  @apply mx-auto;
}

/*.privacy-policy h2{*/
/*    margin-top: 10px;*/
/*    font-size: 1.5rem;*/
/*    text-align: left;*/
/*}*/

/*.privacy-policy h3{*/
/*    font-size: 1.3rem;*/
/*    text-align: left;*/
/*}*/

/*.privacy-policy h4{*/
/*    font-size: 1.15rem;*/
/*    text-align: left;*/
/*    color: #303030;*/
/*}*/

.privacy-policy ul li {
  font-size: 1rem;
  text-align: left;
  margin-left: 2rem;
  display: list-item; /* This has to be "list-item"                                               */
  list-style-type: square; /* See https://developer.mozilla.org/en-US/docs/Web/CSS/list-style-type     */
}

/*.privacy-policy ul li p{*/

/*}*/
