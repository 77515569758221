@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-violet-700;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  @apply text-3xl mb-2 mt-3 font-extrabold;
}

h2 {
  @apply text-2xl mb-1 mt-2 font-extrabold;
}

h3 {
  @apply text-xl mb-0 mt-1 font-extrabold;
}

h4 {
  @apply text-lg;
}

h5 {
  @apply text-base;
}

p {
  text-align: justify;
  margin-bottom: 10px;
  font-family: Montserrat, Arial, sans-serif;
}

ul {
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: right;
}

ul li {
  font-size: 1rem;

  margin-left: 2rem;
  display: list-item; /* This has to be "list-item"                                               */
  /*text-align: justify;*/
  margin-bottom: 0.25rem;
  font-family: Montserrat, Arial, sans-serif;
}

.anchor:target {
  animation-name: blink;
  animation-direction: normal;
  animation-duration: 0.3s;
  animation-iteration-count: 2;
  animation-timing-function: ease;
  background-image: url('../src/resources/images/web-development.png');
}
@keyframes blink {
  0% {
    color: rgba(0, 0, 0, 0.9);
  }
  100% {
    color: rgba(0, 0, 0, 0);
  }
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: underline;
}
