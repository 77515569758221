.list-sub-services {
  @apply border-2 rounded-md m-1 p-1 bg-violet-700 hover:-translate-y-1 hover:shadow-lg;
}

.services-para-style {
  @apply text-black px-8;
}

.services-ul-style {
  @apply text-white;
}
