.page-footer-common {
  @apply mt-auto border-t-2 flex flex-col;
  background: linear-gradient(
    58deg,
    rgba(243, 172, 18, 1) 20%,
    rgba(241, 196, 15, 1) 100%
  );
  /*width: 100vw;*/
  /*max-width: 1290px;*/
  min-height: 100%;
  position: relative;
}

.footer-copyright-bar {
  @apply font-light text-xs mx-auto my-auto w-1/2 bottom-0 mb-8 flex justify-between;
}

.footer-text {
  @apply font-light text-xs m-4 p-3;
}
