.navbar-container {
  background: linear-gradient(
    58deg,
    rgba(243, 172, 18, 1) 20%,
    rgba(241, 196, 15, 1) 100%
  );
  position: relative;
  font-size: 1em;
  width: 100%;
  padding: 0.5rem;
  margin: 1rem;
}

.navBar-logo {
  margin-left: 2rem;
}

.navbar-menu-drop-down {
  position: absolute;
}

.navbar-menu-button {
  @apply transition-all text-white rounded-2xl font-medium focus:bg-amber-500 transform hover:-translate-y-1 hover:shadow-lg text-center p-3 mx-4 leading-5 m-1;
}

.navbar-button-container {
  width: 100%;
  padding: 0.25rem;
  position: relative;
}
