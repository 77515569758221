.company-logo {
  @apply rounded;
  width: 130px;
  height: 2.5rem;
  margin-top: 0.2rem;
}

.version {
  font-size: 0.5rem;
  width: fit-content;
  /*margin-top: 0.5rem;*/
}

.logo-container {
  position: relative;
  padding: 0.5rem;
  /*margin-top: 1rem;*/
}
